<template>
    <b-card class="mb-0">
        <b-row align-h="end" class="mb-2">
            <b-col>
                <b-card-title>Members</b-card-title>
            </b-col>

            <b-col style="text-align: right">
                <b-button class="mr-1" variant="outline-primary" @click="clickAdd">
                    + Member
                </b-button>
            </b-col>
        </b-row>
        <b-table responsive show-empty :items="members" :key="members.id" :fields="membersHeaders" select-mode="single"
            ref="selectableTable">
            <template #cell(created_at)="data">
                {{ convertCreatedDate(data.item.created_at) }}
            </template>
        </b-table>
    </b-card>
</template>

<script>
import {
    BCardTitle,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol
} from "bootstrap-vue";
import { convertDate } from "@/utils/convertDate";
import { membersHeaders } from "@/utils/headers";

export default {
    components: {
        BCardTitle,
        BTable,
        BCard,
        BButton,
        BRow,
        BCol
    },
    props: {
        members: {
            type: Array,
            default: () => []
        },
        project: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            membersHeaders,
        }
    },
    methods: {
        clickAdd() {
            const encodedNextRoute = encodeURIComponent(`/projects/${this.project}`);
            this.$router.push(`/projects/edit/${this.project}?next=${encodedNextRoute}`);
        },
        convertCreatedDate(date) {
            return convertDate(date);
        },
    }
}
</script>