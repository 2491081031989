<template>
  <div v-if="project">
    <b-card title="Project">
      <b-table-simple hover small caption-top responsive>
        <b-thead>
          <b-tr>
            <b-th>Item</b-th>
            <b-th>Description</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>Title</b-td>
            <b-td>{{ project.title }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Description</b-td>
            <b-td>{{ project.description }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Status</b-td>
            <b-td>
              <b-badge :variant="`light-${projectCategoryStatus(project.status)}`">
                {{ projectResolveStatusText(project.status) }}
              </b-badge>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>Members</b-td>
            <b-td>{{ project.members.length }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Id</b-td>
            <b-td>{{ project.id }}</b-td>
          </b-tr>
          <b-tr>
            <b-td>Tasks</b-td>
            <b-td>{{ project.tasks_template.length }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <!-- Activities table -->
    <b-card class="mb-0">
      <b-card-title>Activities</b-card-title>
      <b-table responsive selectable select-mode="single" @row-selected="onRowSelected($event)" show-empty
        :items="activities" :key="activities.id" :fields="activitiesHeaders" ref="selectableTable">

        <!-- Status -->
        <template #cell(status)="data">
          <b-badge :variant="`light-${activityCategoryStatus(data.item.status)}`">
            {{ activityResolveStatusText(data.item.status) }}
          </b-badge>
        </template>

        <!-- Responsible -->
        <template #cell(responsible)="data">
          <b-avatar class="mr-1" size="40" variant="light-primary" :src="data.item.responsible.avatar">
            <b-img v-if="data.item.responsible.avatar != null" fluid :src="data.item.responsible.avatar"></b-img>
            <div v-else>{{ data.item.responsible.name.substr(0, 1).toUpperCase() }}</div>
          </b-avatar>
        </template>

        <!-- Price -->
        <template #cell(price)="row">
          {{ sumTaskPrices(row.item.tasks) + '€' }}
        </template>

        <!-- Actions -->
        <template #cell(actions)="row">
          <b-row>
            <b-col cols="2">
              <b-button class="p-0" v-b-tooltip.hover title="Details" variant="flat" size="sm" @click="clickDetails(row)">
                <feather-icon icon="FileIcon" />
              </b-button>
            </b-col>
            <b-col cols="2">
              <b-button class="p-0" v-b-tooltip.hover title="Delete" variant="flat" size="sm" @click="clickModalAction(row)">
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-card>

    <br>

    <!-- Tasks table -->
    <!-- <TasksTable :tasks="tasks" :next="next" @refetch="refetchData" /> -->

    <br />

    <!-- Members table -->
    <ProjectMembersTable :members="members" :project="project.id" />
  </div>
</template>

<script>
import {
  BCardTitle,
  BTable,
  BCard,
  BButton,
  BRow,
  BCol,
  BIconTrash,
  BModal,
  BIconPen,
  BIconPlusCircle,
  BContainer,
  BAvatar,
  BImg,
  BIconEye,
  BTbody,
  BTh,
  BTd,
  BTr,
  BThead,
  BTableSimple,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBTooltip
} from "bootstrap-vue";
import { tasksHeaders, activitiesHeaders } from "@/utils/headers";
import ProjectMembersTable from "@/components/atoms/projects/ProjectMembersTable.vue";

export default {
  name: "ProjectsDetail",
  components: {
    BCardTitle,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BIconTrash,
    BModal,
    BIconPen,
    BIconPlusCircle,
    BContainer,
    BAvatar,
    BImg,
    BIconEye,
    BTbody,
    BTh,
    BTd,
    BTr,
    BThead,
    BTableSimple,
    BBadge,
    BDropdown,
    BDropdownItem,
    ProjectMembersTable,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      tasksHeaders: tasksHeaders,
      activitiesHeaders: activitiesHeaders,
      next: null,
      id: null
    };
  },
  computed: {
    sumTaskPrices() {
      return (tasks) => {
        if (tasks && tasks.length > 0) {
          const sum = tasks.reduce((total, task) => total + parseFloat(task.price ?? "0"), 0);
          return sum.toFixed(2);
        }
        return 0;
      };
    },
    project() {
      return this.$store.state.projectsConfig.project;
    },
    tasks() {
      return this.project.tasks_template
    },
    members() {
      return this.project.members
    },
    activities() {
      return this.project.activities
    }
  },
  methods: {
    onRowSelected(data) {
      if (data.length) this.$router.push('/activities/' + data[0].id)
    },
    projectCategoryStatus(categ) {
      return categ === 'activated' ?
        'primary' : categ === 'finished' ?
          'success' : 'primary'
    },
    projectResolveStatusText(status) {
      return status === 'activated' ?
        'ACTIVATED' : status === 'finished' ?
          'FINISHED' : '-'
    },
    activityCategoryStatus(categ) {
      return categ === 'activated' ?
        'primary' : categ === 'completed' ?
          'success' : categ === 'cancelled' ?
            'danger' : categ === 'pending' ?
              'warning' : 'primary';
    },
    activityResolveStatusText(categ) {
      return categ === 'activated' ?
        'ACTIVATED' : categ === 'completed' ?
          'COMPLETED' : categ === 'cancelled' ?
            'CANCELLED' : categ === 'pending' ?
              'PENDING' : 'Primary';
    },
    async refetchData() {
      await this.$store.dispatch("getProject", this.id);
    },
    clickDetails(row) {
      this.$router.push('/activities/' + row.item.id)
    },
    clickModalAction(row) {
      this.boxTwo = '';
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete that line.', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,

      })
        .then(async (value) => {
          if (value == true) {
            await this.$store.dispatch("deleteActivityById", row.item.id)
            await this.refetchData()
          }
        });
    },
  },
  async created() {
    this.id = this.$route.params.id
    this.next = encodeURIComponent(`/projects/${this.id}`);
    await this.$store.dispatch("getProject", this.$route.params.id);
  },
};
</script>